import { useState } from "react";
import { Modal, Image } from "antd";
import { EyeOutlined } from "@ant-design/icons";

const PhotoGallery = ({ photos, orderId, API_BASE_PHOTO_URL }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedPhoto, setSelectedPhoto] = useState("");

  const showPhotoModal = (photo) => {
    setSelectedPhoto(photo);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedPhoto("");
  };

  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
      {photos.map((photo, index) => (
        <div key={index} style={{ position: "relative" }}>
          <img
            src={`${API_BASE_PHOTO_URL}/${photo}`}
            alt={`Order ${orderId}photo ${index + 1}`}
            style={{
              width: "100px",
              height: "100px",
              objectFit: "cover",
              cursor: "pointer",
            }}
          />
          <EyeOutlined
            style={{
              position: "absolute",
              top: "5px",
              right: "5px",
              fontSize: "18px",
              color: "#fff",
              background: "rgba(0, 0, 0, 0.5)",
              borderRadius: "50%",
              padding: "5px",
              cursor: "pointer",
            }}
            onClick={() => showPhotoModal(photo)}
          />
        </div>
      ))}

      <Modal
        visible={isModalVisible}
        footer={null}
        onCancel={handleCancel}
        centered
      >
        <Image
          src={`${API_BASE_PHOTO_URL}/${selectedPhoto}`}
          alt="Enlarged photo"
          style={{ width: "100%" }}
        />
      </Modal>
    </div>
  );
};

export default PhotoGallery;
