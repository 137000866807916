import React, { useContext, useEffect, useState } from "react";
import {
  deleteProduct,
  getAllProducts,
  getAllSubCategories,
  searchProduct,
} from "./../AxiosApis/api";
import Inventory from "./Inventory";
import {
  Button,
  Table,
  Modal,
  Row,
  Col,
  Input,
  AutoComplete,
  Space,
  Popconfirm,
  message,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import ProductForm from "./ProductForm";
import { UserContext } from "../../UserContext";

const Products = () => {
  const { userDetails } = useContext(UserContext);
  const [products, setProducts] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  const showModal = (product) => {
    setSelectedProduct(product);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedProduct(null);
  };

  // Fetch Products
  const fetchProducts = async (email) => {
    try {
      const res = await getAllProducts({ email });
      setProducts(res.data.allProducts);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  // Fetch SubCategories
  const fetchSubCategories = async (email) => {
    const res = await getAllSubCategories({ email });
    setSubCategories(res.data.allSubCategory);
  };

  // Map subCategory IDs to names
  const mapSubCategoryNames = () => {
    const subCategoryMap = {};
    subCategories.forEach((subCategory) => {
      subCategoryMap[subCategory._id] = subCategory.name;
    });
    return subCategoryMap;
  };

  // After fetching both products and subcategories, replace subCategory IDs in products
  const getProductsWithSubCategoryNames = () => {
    const subCategoryMap = mapSubCategoryNames();

    return products.map((product) => ({
      ...product,
      subCategoryName: subCategoryMap[product.subCategory] || "Unknown",
    }));
  };

  const handleDelete = async (id) => {
    const email = getEmailForFetch();
    try {
      await deleteProduct(id);
      message.success("Product deleted successfully");
      fetchProducts(email);
    } catch (error) {
      message.error("Failed to delete product");
    }
  };

  const handleSearch = async (value) => {
    const email = getEmailForFetch();
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    setSearchText(value);
    if (value) {
      try {
        const response = await searchProduct({
          email: adminEmail,
          name: value,
        });
        const options = response.data.products.map((product) => ({
          value: product.name,
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchProducts(email);
    }
  };

  const handleSelect = async (value) => {
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    try {
      const response = await searchProduct({
        email: adminEmail,
        name: value,
      });
      setProducts(response.data.products);
    } catch (error) {
      console.error("Error fetching selected products:", error);
    }
  };

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    let email = getEmailForFetch();

    if (email) {
      fetchSubCategories(email);
      fetchProducts(email);
    }
  }, [userDetails]);

  return (
    <Inventory>
      <h2>Products</h2>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by name"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col span={8} offset={8} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => showModal(null)}>
            Create Product <PlusCircleFilled />
          </Button>
        </Col>

        <Col span={24}>
          <Table
            dataSource={getProductsWithSubCategoryNames()}
            rowKey="_id"
            scroll={{ x: "max-content" }}
            columns={[
              { title: "Name", dataIndex: "name" },
              { title: "Quantity", dataIndex: "quantity" },
              {
                title: "SubCategory",
                dataIndex: "subCategoryName",
              },
              {
                title: "DAP",
                dataIndex: "price",
                key: "price",
                render: (price) => `${price.DAP}`,
              },
              {
                title: "MRP",
                dataIndex: "price",
                key: "price",
                render: (price) => `${price.MRP}`,
              },
              {
                title: "Description",
                dataIndex: "description",
              },
              {
                title: "Actions",
                key: "actions",
                render: (product) => (
                  <Space>
                    <EditOutlined
                      style={{ color: "#1890ff", marginRight: 12 }}
                      onClick={() => showModal(product)}
                    />
                    <Popconfirm
                      title="Are you sure to delete this Product?"
                      okText="Yes"
                      cancelText="No"
                      onConfirm={() => handleDelete(product._id)}
                    >
                      <DeleteOutlined style={{ color: "#ff4d4f" }} />
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
          />
        </Col>
      </Row>
      <Modal
        title={selectedProduct ? "Edit Product" : "Create Product"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <ProductForm
          product={selectedProduct}
          subCategories={subCategories}
          onSuccess={() => fetchProducts(getEmailForFetch())}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </Inventory>
  );
};

export default Products;
