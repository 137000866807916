import React, { useContext, useEffect, useState } from "react";
import {
  getAllSubCategories,
  getAllCategories,
  deleteSubCategory,
  searchSubCategory,
} from "./../AxiosApis/api";
import SubCategoryForm from "./SubCategoryForm";
import {
  Button,
  Table,
  Modal,
  Row,
  Col,
  Input,
  AutoComplete,
  Popconfirm,
  message,
  Space,
} from "antd";
import {
  DeleteOutlined,
  EditOutlined,
  PlusCircleFilled,
  SearchOutlined,
} from "@ant-design/icons";
import Inventory from "./Inventory";
import { UserContext } from "../../UserContext";

const Subcategory = () => {
  const { userDetails } = useContext(UserContext);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  const fetchSubCategories = async (email) => {
    const res = await getAllSubCategories({ email });
    setSubCategories(res.data.allSubCategory);
  };

  const fetchCategories = async (email) => {
    const res = await getAllCategories({ email });
    setCategories(res.data.allcategory);
  };

  const mapCategoryNames = () => {
    const categoryMap = {};
    categories.forEach((category) => {
      categoryMap[category._id] = category.name;
    });
    return categoryMap;
  };

  const getSubCategoriesWithCategoryNames = () => {
    const categoryMap = mapCategoryNames();
    return subCategories.map((subCategory) => ({
      ...subCategory,
      categoryName: categoryMap[subCategory.category] || "Unknown",
    }));
  };

  const showModal = (subcategory) => {
    if (subcategory) {
      setSelectedSubCategory(subcategory);
    } else {
      setSelectedSubCategory(null);
    }
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedSubCategory(null);
  };

  const handleDelete = async (id) => {
    const email = getEmailForFetch();
    try {
      await deleteSubCategory(id);
      message.success("Sub-Category deleted successfully");
      fetchSubCategories(email);
    } catch (error) {
      message.error("Failed to delete Sub-category");
    }
  };

  const handleSearch = async (value) => {
    const email = getEmailForFetch();
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    setSearchText(value);
    if (value) {
      try {
        const response = await searchSubCategory({
          email: adminEmail,
          name: value,
        });
        const options = response.data.data.map((subCat) => ({
          value: subCat.name,
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchSubCategories(email);
    }
  };

  const handleSelect = async (value) => {
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    try {
      const response = await searchSubCategory({
        email: adminEmail,
        name: value,
      });
      setSubCategories(response.data.data);
    } catch (error) {
      console.error("Error fetching selected subcategory:", error);
    }
  };

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    const email = getEmailForFetch();
    if (email) {
      fetchCategories(email);
      fetchSubCategories(email);
    }
  }, [userDetails]);

  return (
    <Inventory>
      <h2>Subcategories</h2>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by name"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col span={8} offset={8} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={() => showModal(null)}>
            Create Sub Category <PlusCircleFilled />
          </Button>
        </Col>

        <Col span={24}>
          <Table
            dataSource={getSubCategoriesWithCategoryNames()}
            rowKey="_id"
            columns={[
              { title: "Name", dataIndex: "name", key: "name" },
              {
                title: "Category",
                dataIndex: "categoryName",
                key: "categoryName",
              },
              {
                title: "Actions",
                key: "actions",
                render: (subcategory) => (
                  <Space>
                    <EditOutlined
                      style={{ color: "#1890ff", marginRight: 12 }}
                      onClick={() => showModal(subcategory)}
                    />
                    <Popconfirm
                      title="Are you sure to delete this Sub-Category?"
                      onConfirm={() => handleDelete(subcategory._id)}
                      okText="Yes"
                      cancelText="No"
                    >
                      <DeleteOutlined style={{ color: "#ff4d4f" }} />
                    </Popconfirm>
                  </Space>
                ),
              },
            ]}
          />
        </Col>
      </Row>

      <Modal
        title={selectedSubCategory ? "Edit Subcategory" : "Create Subcategory"}
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <SubCategoryForm
          subCategory={selectedSubCategory}
          categories={categories}
          onSuccess={() => fetchSubCategories(getEmailForFetch())}
          setIsModalOpen={setIsModalOpen}
        />
      </Modal>
    </Inventory>
  );
};

export default Subcategory;
