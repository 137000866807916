import React, { useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Select,
  InputNumber,
  Row,
  Col,
  message,
} from "antd";
import { createProduct, updateProduct } from "./../AxiosApis/api";

const { Option } = Select;

const ProductForm = ({ product, subCategories, onSuccess, setIsModalOpen }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields(); // Clear previous fields
    if (product) {
      form.setFieldsValue(product); // Set values for editing
    }
  }, [product, form]);

  const onFinish = async (values) => {
    try {
      if (product) {
        await updateProduct(product._id, values);
        message.success("Product updated successfully!");
      } else {
        await createProduct(values);
        form.resetFields();
      }
      setIsModalOpen(false);
      onSuccess();
    } catch (error) {
      console.error("Failed to save product:", error);
    }
  };

  return (
    <Form form={form} layout="vertical" onFinish={onFinish}>
      <Form.Item
        name="name"
        label="Product Name"
        rules={[{ required: true, message: "Please enter the product name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name="quantity"
        label="Quantity"
        rules={[{ required: true, message: "Please enter the quantity!" }]}
      >
        <InputNumber min={0} />
      </Form.Item>
      <Form.Item
        name="subCategory"
        label="SubCategory"
        rules={[{ required: true, message: "Please select a subcategory!" }]}
      >
        <Select>
          {subCategories.map((sub) => (
            <Option key={sub._id} value={sub._id}>
              {sub.name}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name={["price", "DAP"]}
        label="DAP"
        rules={[{ required: true, message: "Please enter the DAP!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        name={["price", "MRP"]}
        label="MRP"
        rules={[{ required: true, message: "Please enter the MRP!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item name="description" label="Description">
        <Input />
      </Form.Item>
      <Row>
        <Col span={24} style={{ textAlign: "end" }}>
          <Button type="primary" htmlType="submit">
            {product ? "Update" : "Add"} Product
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;
