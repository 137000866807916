import React, { useContext, useEffect, useState } from "react";
import AppLayout from "./../../layout/Layout";
import {
  Button,
  Col,
  Row,
  Table,
  Popconfirm,
  Space,
  Modal,
  Form,
  Input,
  message,
  Select,
  notification,
  Tag,
  AutoComplete,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  deleteStaff,
  getAllAdmins,
  searchAllAdmins,
  updateStaff,
} from "../AxiosApis/api";
import CreateSuperStaff from "./CreateSuperStaff";

const SuperStaff = () => {
  const [form] = Form.useForm();
  const [staffModal, setStaffModal] = useState(false);
  const [editStaffModal, setEditStaffModal] = useState(false);
  const [staffList, setStaffList] = useState([]);
  const [staffId, setStaffId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);

  const showStaffModal = () => {
    setStaffModal(true);
  };

  const handleStaffCancel = () => {
    setStaffModal(false);
    form.resetFields();
  };

  const showEditStaffModal = (record) => {
    form.setFieldsValue(record);
    setStaffId(record._id);
    setEditStaffModal(true);
  };

  const handleEditStaffModalCancel = () => {
    setEditStaffModal(false);
    form.resetFields();
  };

  useEffect(() => {
    fetchStaff();
  }, []);

  const fetchStaff = async () => {
    try {
      const response = await getAllAdmins();

      const sortedAdminList = response.data.data.sort((a, b) =>
        a.name.localeCompare(b.name)
      );
      setStaffList(sortedAdminList);
    } catch (error) {
      console.error("Error fetching staff:", error);
    }
  };

  const handleUpdateStaff = async (values) => {
    try {
      await updateStaff(staffId, values);
      notification.success({
        message: "Success",
        description: "Staff updated successfully",
      });
      setEditStaffModal(false);
      form.resetFields();
      fetchStaff();
    } catch (error) {
      console.error("Error updating Staff:", error);
      notification.error({
        message: "Error",
        description:
          error.response?.data?.message ||
          "There was an error updating the Staff",
      });
    }
  };

  const handleDelete = async (staffID) => {
    try {
      await deleteStaff(staffID);
      message.success("Staff deleted successfully!");
      setStaffList(staffList.filter((staff) => staff._id !== staffID));
    } catch (error) {
      console.error("Error deleting Staff:", error);
      message.error(error.response?.data?.message || "Failed to delete staff.");
    }
  };

  const columns = [
    { title: "Name", dataIndex: "name", key: "name", width: 120 },
    { title: "Staff ID", dataIndex: "staffID", key: "staffID", width: 50 },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      width: 50,

      render: (role) => {
        let color;
        switch (role) {
          case "register":
            color = "green";
            break;
          case "engineer":
            color = "orange";
            break;
          case "transport":
            color = "gray";
            break;
          case "worker":
            color = "orange";
            break;
          case "admin":
            color = "red";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{role.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
      width: 400,
      render: (address) => `${address?.fulladdress}`,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 60,
    },

    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => showEditStaffModal(record)}
          />
          <Popconfirm
            title="Are you sure to delete this staff?"
            onConfirm={() => handleDelete(record?._id)}
          >
            <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  const handleSearch = async (value) => {
    setSearchText(value);
    if (value) {
      try {
        const response = await searchAllAdmins({ name: value });
        const options = response.data.data.map((staff) => ({
          value: staff.name,
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchStaff();
    }
  };

  const handleSelect = async (value) => {
    try {
      const response = await searchAllAdmins({ name: value });
      setStaffList(response.data.data);
    } catch (error) {
      console.error("Error fetching selected products:", error);
    }
  };

  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder={"Search By Name"}
              allowClear
            />
          </AutoComplete>
        </Col>
        <Col span={8} offset={8} style={{ textAlign: "end" }}>
          <Button type="primary" onClick={showStaffModal}>
            Create Staff <PlusOutlined />
          </Button>
        </Col>

        <Col span={24}>
          <Table
            pagination={false}
            columns={columns}
            dataSource={staffList}
            rowKey="staffID"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>

      <Modal
        title="Add Staff"
        visible={staffModal}
        onCancel={handleStaffCancel}
        footer={null}
      >
        <CreateSuperStaff
          staffList={staffList}
          setStaffList={setStaffList}
          setStaffModal={setStaffModal}
        />
      </Modal>

      <Modal
        title="Edit Staff"
        visible={editStaffModal}
        onCancel={handleEditStaffModalCancel}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={handleUpdateStaff}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please Enter your name!" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="phoneNumber"
                label="Phone Number"
                rules={[
                  { required: true, message: "Please enter your number!" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="personalemail" label="Personal Email">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="aadhar" label="Aadhar Number">
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="email"
                label="Update Office Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter your Office email!",
                  },
                ]}
              >
                <Input type="email" />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name="oldPassword" label="Old Password">
                <Input.Password />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="password" label="New Password">
                <Input.Password />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="role"
                label="Role"
                rules={[{ required: true, message: "Please Select the Role" }]}
              >
                <Select>
                  <Select.Option value="admin">SPF</Select.Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item
                name={["address", "fulladdress"]}
                label="Full Address"
                rules={[
                  { required: true, message: "Please Enter the Full Address" },
                ]}
              >
                <Input.TextArea rows={4} />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{ marginRight: "8px" }}
            >
              Save
            </Button>
            <Button onClick={handleEditStaffModalCancel}>Cancel</Button>
          </Form.Item>
        </Form>
      </Modal>
    </AppLayout>
  );
};

export default SuperStaff;
