import {
  Button,
  Col,
  Row,
  Form,
  Input,
  notification,
  AutoComplete,
  Upload,
} from "antd";
import React, { useState, useEffect, useContext } from "react";
import { PlusOutlined } from "@ant-design/icons";
import {
  createOrder,
  getAllProductList,
  getAllVendorList,
} from "../AxiosApis/api";
import { UserContext } from "../../UserContext";

// B2C
const CreateOrder = ({ fetchOrders, setIsModalVisible }) => {
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const [productOptions, setProductOptions] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [filteredProductNames, setFilteredProductNames] = useState([]);
  const [filteredVendorNames, setFilteredVendorNames] = useState([]);
  const [modelPhotos, setModelPhotos] = useState([]);
  const [serialPhotos, setSerialPhotos] = useState([]);
  const [descriptionPhotos, setDescriptionPhotos] = useState([]);
  const [previousBillPhotos, setPreviousBillPhotos] = useState([]);

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    const email = getEmailForFetch();
    if (email) {
      fetchProducts(email);
      fetchVendor(email);
    }
  }, [userDetails]);

  const fetchProducts = async (email) => {
    try {
      const response = await getAllProductList({ email });
      const products = response.data.products.map((product) => ({
        value: product.name,
      }));
      setProductOptions(products);
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };

  const fetchVendor = async (email) => {
    try {
      const response = await getAllVendorList({ email });
      const vendors = response.data.vendors.map((vendor) => ({
        value: vendor.name,
      }));
      setVendorOptions(vendors);
    } catch (error) {}
  };

  const handleCreateOrder = async (values) => {
    const formData = new FormData();

    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("phoneNumber", values.phoneNumber);
    formData.append("productName", values.productName);
    formData.append("productModel", values.productModel);
    formData.append("serialno", values.serialno);
    formData.append("description", values.description);
    formData.append("address[fulladdress]", values.address.fulladdress);

    modelPhotos.forEach((photo) =>
      formData.append("modelphotos", photo.originFileObj)
    );
    serialPhotos.forEach((photo) =>
      formData.append("serialphotos", photo.originFileObj)
    );
    descriptionPhotos.forEach((photo) =>
      formData.append("descriptionphotos", photo.originFileObj)
    );
    previousBillPhotos.forEach((photo) =>
      formData.append("previousbillphotos", photo.originFileObj)
    );

    try {
      await createOrder(formData);

      notification.success({
        message: "Success",
        description: "Order created successfully",
      });
      fetchOrders(); // Refresh the order list
      setIsModalVisible(false); // Close the modal
      form.resetFields(); // Reset form fields
      setModelPhotos([]);
      setSerialPhotos([]);
      setDescriptionPhotos([]);
      setPreviousBillPhotos([]);
    } catch (error) {
      console.error("Error creating order:", error);
      notification.error({
        message: "Error",
        description: "There was an error creating the order",
      });
    }
  };

  const handleProductSearch = (value) => {
    if (value) {
      const filtered = productOptions.filter((product) =>
        product.value.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredProductNames(filtered);
    } else {
      setFilteredProductNames([]);
    }
  };

  const validateProductName = (_, value) => {
    if (!value || productOptions.some((product) => product.value === value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Select a valid brand name"));
  };

  const handleVendorSearch = (value) => {
    if (value) {
      const filtered = vendorOptions.filter((vendor) =>
        vendor.value.toLowerCase().includes(value.toLowerCase())
      );
      setFilteredVendorNames(filtered);
    } else {
      setFilteredVendorNames([]);
    }
  };

  const validateVendorName = (_, value) => {
    if (!value || vendorOptions.some((vendor) => vendor.value === value)) {
      return Promise.resolve();
    }
    return Promise.reject(new Error("Select a valid Vendor name"));
  };

  const handleUpload =
    (setPhotos) =>
    ({ fileList }) => {
      setPhotos(fileList);
    };

  return (
    <Row>
      <Form form={form} layout="vertical" onFinish={handleCreateOrder}>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Vendor Name"
              rules={[
                { required: true, message: "Enter Vendor Name" },
                { validator: validateVendorName },
              ]}
            >
              <AutoComplete
                options={filteredVendorNames}
                onSearch={handleVendorSearch}
                placeholder="Enter Vendor name"
                filterOption={false}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="email" label="Email">
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="phoneNumber"
              label="Phone Number"
              rules={[{ required: true, message: "Please enter your number!" }]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="productName"
              label="Brand Name"
              rules={[
                { required: true, message: "Enter Brand Name" },
                { validator: validateProductName },
              ]}
            >
              <AutoComplete
                options={filteredProductNames}
                onSearch={handleProductSearch}
                placeholder="Enter Brand name"
                filterOption={false}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="productModel" label="Brand Model">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="modelphotos" label="Upload Brand Model Photos">
              <Upload
                listType="picture-card"
                fileList={modelPhotos}
                onChange={handleUpload(setModelPhotos)}
              >
                {modelPhotos.length < 5 && <PlusOutlined />}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item name="serialno" label="Serial Number">
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="serialphotos" label="Upload Serial Number Photos">
              <Upload
                listType="picture-card"
                fileList={serialPhotos}
                onChange={handleUpload(setSerialPhotos)}
              >
                {serialPhotos.length < 5 && <PlusOutlined />}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="description"
              label="Issue"
              rules={[{ required: true, message: "Please Enter The Issue" }]}
            >
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="descriptionphotos" label="Upload Issue Photos">
              <Upload
                listType="picture-card"
                fileList={descriptionPhotos}
                onChange={handleUpload(setDescriptionPhotos)}
              >
                {descriptionPhotos.length < 5 && <PlusOutlined />}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name={["address", "fulladdress"]}
              label="Full Address"
              rules={[{ required: true, message: "Please enter Full Address" }]}
            >
              <Input.TextArea rows={3} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="previousbillphotos"
              label="Upload Previous Bill Photos"
            >
              <Upload
                listType="picture-card"
                multiple={true}
                fileList={previousBillPhotos}
                onChange={handleUpload(setPreviousBillPhotos)}
                beforeUpload={() => false}
              >
                {previousBillPhotos.length < 5 && <PlusOutlined />}
              </Upload>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Row>
  );
};

export default CreateOrder;
