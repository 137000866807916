import { React, useState } from "react";
import { Button, Col, message, Row, Tag } from "antd";
import {
  API_BASE_PHOTO_URL,
  generateInvoice,
  getQrCode,
} from "../AxiosApis/api";

import { jsPDF } from "jspdf"; // For downloading the PDF
import html2canvas from "html2canvas"; // For capturing screenshot of the element
import PhotoGallery from "./photoGallery";

const ViewOrder = ({ selectedOrder, getStatusColor }) => {
  const [qrCode, setQrCode] = useState("");
  const [isQrGenerated, setIsQrGenerated] = useState(false);
  const [invoiceURL, setInvoiceURL] = useState("");
  const handleGenerateQRCode = async () => {
    try {
      const response = await getQrCode(selectedOrder._id); // Your existing API call for QR code
      setQrCode(response?.data?.qrCode);
      setIsQrGenerated(true); // Hide button, show QR code and details
    } catch (error) {
      console.error("Error generating QR code:", error);
    }
  };

  // Function to download the QR code and details as a PDF
  const handleDownload = async () => {
    const input = document.getElementById("qrDetailsSection");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10);
      pdf.save("QRCode_OrderDetails.pdf");
    });
  };

  const handlePrintQRCode = () => {
    const printWindow = window.open("", "_blank");
    printWindow.document.write(`
        <html>
        <head>
          <title>Print QR Code & Details</title>
          <style>
            body { text-align: center; font-family: Arial, sans-serif; }
            img { width: 200px; height: 200px; margin-bottom: 20px; }
            p { font-size: 16px; margin: 5px 0; }
            .order-details { margin-top: 20px; }
          </style>
        </head>
        <body>
          <h2>Order QR Code & Details</h2>
          <img src="${qrCode}" alt="QR Code" />
          <div class="order-details">
            <p><strong>Name:</strong> ${selectedOrder.name}</p>
            <p><strong>Order ID:</strong> ${selectedOrder.orderId}</p>
            <p><strong>Phone Number:</strong> ${selectedOrder.phoneNumber}</p>
          </div>
        </body>
      </html>
    `);
    printWindow.document.close();
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  const handleGenerateInvoice = async () => {
    try {
      const response = await generateInvoice(selectedOrder.orderId);
      setInvoiceURL(response.data);
      message.success("Invoice generated successfully!");
    } catch (error) {
      console.error("Error generating invoice:", error);
      message.error("Failed to generate invoice");
    }
  };

  const handleDownloadInvoice = () => {
    if (!invoiceURL) {
      message.error("No invoice generated!");
      return;
    }

    // Create a Blob URL from the PDF data
    const blob = new Blob([invoiceURL], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    // Create an anchor element and trigger a download
    const link = document.createElement("a");
    link.href = url;
    link.download = `Invoice_${selectedOrder._id}.pdf`;
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url); // Release memory
  };

  const handlePrintInvoice = () => {
    if (!invoiceURL) {
      message.error("No invoice generated!");
      return;
    }

    // Create a Blob URL for the PDF
    const blob = new Blob([invoiceURL], { type: "application/pdf" });
    const url = window.URL.createObjectURL(blob);

    // Open a new window with the PDF and trigger print
    const printWindow = window.open(url, "_blank");
    printWindow.onload = () => {
      printWindow.print();
    };
  };

  return (
    <>
      {" "}
      {selectedOrder && (
        <div>
          <Row gutter={[16, 16]}>
            <Col span={24}>
              <Button type="primary" onClick={handleGenerateQRCode}>
                Generate QR Code
              </Button>
            </Col>

            <Col span={24} style={{ textAlign: "right" }}>
              {isQrGenerated && (
                <div>
                  <Row
                    id="qrDetailsSection"
                    style={{ width: "100%", marginTop: "10px" }}
                    gutter={[8, 0]}
                  >
                    <Col span={10}>
                      {qrCode && (
                        <img
                          src={qrCode}
                          alt="QR Code"
                          style={{
                            width: "100px",
                            height: "100px",
                            marginRight: "20px",
                          }}
                        />
                      )}
                    </Col>
                    <Col span={14} style={{ textAlign: "initial" }}>
                      <p style={{ margin: 0 }}>
                        <strong>Name:</strong> {selectedOrder.name}
                      </p>
                      <p style={{ margin: 0 }}>
                        <strong>Order ID:</strong> {selectedOrder.orderId}
                      </p>
                      <p style={{ margin: 0 }}>
                        <strong>Phone Number:</strong>{" "}
                        {selectedOrder.phoneNumber}
                      </p>
                    </Col>
                  </Row>

                  <Row
                    style={{ width: "100%", marginTop: "10px" }}
                    gutter={[8, 0]}
                  >
                    <Col span={12}>
                      <Button
                        onClick={handleDownload}
                        disabled={!qrCode}
                        type="primary"
                        ghost
                        style={{ width: "100%" }}
                      >
                        Download
                      </Button>
                    </Col>
                    <Col span={12}>
                      <Button
                        onClick={handlePrintQRCode}
                        disabled={!qrCode}
                        type="primary"
                        style={{ width: "100%" }}
                      >
                        Print
                      </Button>
                    </Col>
                  </Row>
                </div>
              )}
            </Col>
          </Row>

          <p>
            <strong>Name:</strong> {selectedOrder.name}
          </p>
          <p>
            <strong>Email:</strong> {selectedOrder.email}
          </p>
          <p>
            <strong>Order ID:</strong> {selectedOrder.orderId}
          </p>
          <p>
            <strong>Phone Number:</strong> {selectedOrder.phoneNumber}
          </p>
          <p>
            <strong>Product Name:</strong> {selectedOrder.productName}
          </p>
          <p>
            <strong>Product Model:</strong> {selectedOrder.productModel}
            <PhotoGallery
              photos={selectedOrder.modelphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Serial Number:</strong> {selectedOrder.serialno}
            <PhotoGallery
              photos={selectedOrder.serialphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Issue:</strong> {selectedOrder.description}
            <PhotoGallery
              photos={selectedOrder.descriptionphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Previous Bills:</strong>
            <PhotoGallery
              photos={selectedOrder.previousbillphotos}
              orderId={selectedOrder.orderId}
              API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
            />
          </p>

          <p>
            <strong>Status:</strong>{" "}
            <Tag color={getStatusColor(selectedOrder.status)}>
              {selectedOrder.status.toUpperCase()}
            </Tag>
          </p>
          <p>
            <strong>Address:</strong> {selectedOrder.address.fulladdress}
          </p>
          <p>
            <strong>Created At:</strong>{" "}
            {new Date(selectedOrder.createdAt).toLocaleString()}
          </p>
          <p>
            <strong>Last Modified:</strong>{" "}
            {new Date(selectedOrder.lastModified).toLocaleString()}
          </p>

          <h2>Generate Bill</h2>
          <Row gutter={[8, 8]}>
            <Col span={8}>
              <Button type="primary" onClick={handleGenerateInvoice}>
                Generate Bill
              </Button>
            </Col>

            <Col span={8}>
              <Button
                type="primary"
                onClick={handlePrintInvoice}
                disabled={!invoiceURL}
              >
                Print Bill
              </Button>
            </Col>
            <Col span={8}>
              <Button
                type="primary"
                onClick={handleDownloadInvoice}
                disabled={!invoiceURL}
              >
                Download Bill
              </Button>
            </Col>
          </Row>

          <h2>Photos At Pickup</h2>
          <PhotoGallery
            photos={selectedOrder.photos}
            orderId={selectedOrder.orderId}
            API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
          />

          <h2>Photos At Resolved</h2>
          <PhotoGallery
            photos={selectedOrder.resolvedphotos}
            orderId={selectedOrder.orderId}
            API_BASE_PHOTO_URL={API_BASE_PHOTO_URL}
          />
          <h2>Order History</h2>
          <div>
            <p>
              <strong>Assigned to Pickup At:</strong>{" "}
              {selectedOrder.assignPickupTime
                ? new Date(selectedOrder.assignPickupTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Pickup Person:</strong>{" "}
              {selectedOrder?.pickupPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Picked Up At:</strong>{" "}
              {selectedOrder.pickupTime
                ? new Date(selectedOrder.pickupTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Order At Store:</strong>{" "}
              {selectedOrder.storeDeliveryTime
                ? new Date(selectedOrder.storeDeliveryTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Assigned to Engineer At:</strong>{" "}
              {selectedOrder.assignEngineerTime
                ? new Date(selectedOrder.assignEngineerTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Engineer Name:</strong>
              {selectedOrder?.technician?.name || "N/A"}
            </p>
            <p>
              <strong>Resolved At:</strong>{" "}
              {selectedOrder.resolvedTime
                ? new Date(selectedOrder.resolvedTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Issue Reported At:</strong>{" "}
              {selectedOrder.issue_ReportedTime
                ? new Date(selectedOrder.issue_ReportedTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Assigned to Delivery At:</strong>{" "}
              {selectedOrder.deliveryAssignedTime
                ? new Date(selectedOrder.deliveryAssignedTime).toLocaleString()
                : "N/A"}
            </p>
            <p>
              <strong>Delivery Person: </strong>
              {selectedOrder?.deliveryPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Product Delivered At:</strong>{" "}
              {selectedOrder.deliveredTime
                ? new Date(selectedOrder.deliveredTime).toLocaleString()
                : "N/A"}
            </p>
          </div>

          <h2>Cancellation Details</h2>
          <div>
            <p>
              <strong>Cancelled by:</strong>{" "}
              {selectedOrder?.cancelledPickupPerson?.name || "N/A"}
            </p>

            <p>
              <strong>Reason for Cancellation:</strong>{" "}
              {selectedOrder?.reasonforCancelPickUp || "N/A"}
            </p>
            <p>
              <strong>Cancelled Time:</strong>{" "}
              {selectedOrder?.cancelAssignPickUpTime
                ? new Date(
                    selectedOrder.cancelAssignPickUpTime
                  ).toLocaleString()
                : "N/A"}
            </p>

            <p>
              <strong>Cancelled by Engineer:</strong>{" "}
              {selectedOrder.cancelledEngineerPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Reason for Engineer Cancellation:</strong>{" "}
              {selectedOrder.reasonForCancelEngineer || "N/A"}
            </p>
            <p>
              <strong>Engineer Cancelled At:</strong>{" "}
              {selectedOrder.cancelAssignEngineerTime
                ? new Date(
                    selectedOrder.cancelAssignEngineerTime
                  ).toLocaleString()
                : "N/A"}
            </p>

            <p>
              <strong>Cancelled by Delivery Person:</strong>{" "}
              {selectedOrder.cancelledDeliveryPerson?.name || "N/A"}
            </p>
            <p>
              <strong>Reason for Delivery Cancellation:</strong>{" "}
              {selectedOrder.reasonForCancelDelivery || "N/A"}
            </p>
            <p>
              <strong>Delivery Cancelled At:</strong>{" "}
              {selectedOrder.cancelAssignDeliveryTime
                ? new Date(
                    selectedOrder.cancelAssignDeliveryTime
                  ).toLocaleString()
                : "N/A"}
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ViewOrder;
