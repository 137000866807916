import React, { useState, useEffect, useContext } from "react";
import AppLayout from "./../../layout/Layout";
import {
  Button,
  Col,
  Row,
  Table,
  Modal,
  Form,
  Input,
  notification,
  Popconfirm,
  Space,
  AutoComplete,
  Radio,
  Tag,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  SearchOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import CreateOrder from "./createOrders";
import EditOrder from "./EditOrder";
import ViewOrder from "./ViewOrder";
import { deleteOrder, getAllOrders, searchOrder } from "../AxiosApis/api";
import CreateB2BOrder from "./createB2BOrders";
import { UserContext } from "../../UserContext";

const Order = () => {
  const [form] = Form.useForm();
  const { userDetails } = useContext(UserContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [editOrderId, setEditOrderId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [searchOptions, setSearchOptions] = useState([]);
  const [searchType, setSearchType] = useState("name");
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [viewOrderModalVisible, setViewOrderModalVisible] = useState(false);
  const [orderType, setOrderType] = useState("B2B");

  const getEmailForFetch = () => {
    let email = null;
    if (userDetails?.role === "admin") {
      email = userDetails?.email;
    } else if (
      userDetails?.role === "register" ||
      userDetails?.role === "engineer" ||
      userDetails?.role === "delivery" ||
      userDetails?.role === "pickup"
    ) {
      email = userDetails?.admin?.email;
    }
    return email;
  };

  useEffect(() => {
    const email = getEmailForFetch();
    if (email) {
      fetchOrders(email);
    }
  }, [userDetails]);

  const fetchOrders = async (email) => {
    setLoading(true);
    try {
      const response = await getAllOrders({ email });

      const sortedOrders = response.data.data.orders.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setOrders(sortedOrders);
    } catch (error) {
      console.error("Error fetching orders:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteOrder = async (id) => {
    const email = getEmailForFetch();
    setLoading(true);
    try {
      await deleteOrder(id);
      notification.success({
        message: "Success",
        description: "Order deleted successfully",
      });
      fetchOrders(email);
    } catch (error) {
      console.error("Error deleting order:", error);
      notification.error({
        message: "Error",
        description: "There was an error deleting the order",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleEditClick = (order) => {
    setId(order._id);
    setEditOrderId(order.orderId);
    form.setFieldsValue(order);
    setIsEditModalVisible(true);
    setSelectedOrder(order);
  };

  const handleSearch = async (value) => {
    const email = getEmailForFetch();
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    setSearchText(value);
    if (value) {
      try {
        const response = await searchOrder({
          email: adminEmail,
          [searchType]: value,
        });
        const options = response.data.data.orders.map((order) => ({
          value: searchType === "name" ? order.name : order.orderId,
        }));
        setSearchOptions(options);
      } catch (error) {
        console.error("Error fetching search suggestions:", error);
      }
    } else {
      setSearchOptions([]);
      fetchOrders(email);
    }
  };

  const handleSelect = async (value) => {
    const adminEmail =
      userDetails?.role === "admin"
        ? userDetails?.email
        : userDetails?.admin?.email;
    try {
      const response = await searchOrder({
        email: adminEmail,
        [searchType]: value,
      });
      setOrders(response.data.data.orders);
    } catch (error) {
      console.error("Error fetching selected products:", error);
    }
  };

  const handleViewOrder = (order) => {
    setSelectedOrder(order);
    setViewOrderModalVisible(true);
  };

  const handleCloseViewOrderModal = () => {
    setViewOrderModalVisible(false);
    setSelectedOrder(null);
  };

  const handleCreateOrderB2B = () => {
    setIsModalVisible(true);
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "orange";
      case "assign_pick_up":
        return "geekblue";
      case "picked_up":
        return "green";
      case "order_At_Store":
        return "volcano";
      case "in_progress":
        return "purple";
      case "issue_reported":
        return "pink";
      case "resolved":
        return "cyan";
      case "assign_delivery":
        return "gold";
      case "delivered":
        return "green";
      default:
        return "blue";
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 90,
    },
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
      width: 50,
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "phoneNumber",
      width: 50,
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
      width: 90,
    },
    {
      title: "Product Model",
      dataIndex: "productModel",
      key: "productModel",
      width: 90,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: 50,
      render: (status) => {
        return <Tag color={getStatusColor(status)}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Actions",
      key: "actions",
      width: 50,
      render: (text, record) => (
        <Space size="middle">
          <EyeOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleViewOrder(record)}
          />
          <EditOutlined
            style={{ cursor: "pointer" }}
            onClick={() => handleEditClick(record)}
          />
          <Popconfirm
            title="Are you sure to delete this order?"
            onConfirm={() => handleDeleteOrder(record._id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteOutlined style={{ cursor: "pointer", color: "red" }} />
          </Popconfirm>
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={[12, 20]}>
        <Col span={8}>
          <Radio.Group
            onChange={(e) => setSearchType(e.target.value)}
            value={searchType}
            style={{ marginBottom: "16px" }}
          >
            <Radio.Button value="name">Name</Radio.Button>
            <Radio.Button value="orderId">Order ID</Radio.Button>
          </Radio.Group>

          <AutoComplete
            options={searchOptions}
            style={{ width: "100%" }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            value={searchText}
            onChange={(value) => setSearchText(value)}
          >
            <Input
              prefix={<SearchOutlined />}
              placeholder="Search by name or order ID"
              allowClear
            />
          </AutoComplete>
        </Col>

        <Col span={8} offset={8} style={{ textAlign: "end" }}>
          <Radio.Group
            onChange={(e) => setOrderType(e.target.value)}
            value={orderType}
          >
            <Radio value="B2B">B2B</Radio>
            <Radio value="B2C">B2C</Radio>
          </Radio.Group>

          <Button type="primary" onClick={handleCreateOrderB2B}>
            Create Order <PlusOutlined />
          </Button>
        </Col>
        <Col span={24}>
          <Table
            loading={loading}
            pagination={false}
            columns={columns}
            dataSource={orders}
            rowKey="_id"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>

      {orderType === "B2B" ? (
        <Modal
          title="Create B2B Order"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <CreateOrder
            fetchOrders={() => fetchOrders(getEmailForFetch())}
            setIsModalVisible={setIsModalVisible}
          />
        </Modal>
      ) : (
        <Modal
          title="Create B2C Order"
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={null}
        >
          <CreateB2BOrder
            fetchOrders={() => fetchOrders(getEmailForFetch())}
            setIsModalVisible={setIsModalVisible}
          />
        </Modal>
      )}
      <Modal
        title="Edit Order"
        visible={isEditModalVisible}
        onCancel={() => setIsEditModalVisible(false)}
        footer={null}
      >
        <EditOrder
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          form={form}
          id={id}
          editOrderId={editOrderId}
          fetchOrders={() => fetchOrders(getEmailForFetch())}
          setLoading={setLoading}
          setIsEditModalVisible={setIsEditModalVisible}
        />
      </Modal>

      <Modal
        title="Order Details"
        visible={viewOrderModalVisible}
        onCancel={handleCloseViewOrderModal}
        footer={null}
      >
        <ViewOrder
          selectedOrder={selectedOrder}
          setSelectedOrder={setSelectedOrder}
          getStatusColor={getStatusColor}
        />
      </Modal>
    </AppLayout>
  );
};

export default Order;
