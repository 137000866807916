import React, { createContext, useEffect, useState } from "react";
import { getLoggedInUser } from "./components/AxiosApis/api";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userDetails, setUserDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // Step 1: Retrieve the token from localStorage
        const token = localStorage.getItem("token");

        if (!token) {
          throw new Error("No token found. Please log in again.");
        }

        // Step 2: Send the token with the request using the Authorization header
        const response = await getLoggedInUser(token);
        setUserDetails(response?.data?.data?.user);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ userDetails, loading }}>
      {children}
    </UserContext.Provider>
  );
};
