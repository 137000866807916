import React, { useContext, useEffect, useState } from "react";
import {
  Col,
  Row,
  Space,
  Table,
  Tag,
  Modal,
  Button,
  notification,
  Input,
  Upload,
} from "antd";
import AppLayout from "../../layout/Layout";
import { UserContext } from "../../UserContext";
import { EditOutlined, UploadOutlined } from "@ant-design/icons";
import {
  cancelAssignEngineer,
  getEngineerOrders,
  markAsResolved,
  reportIssue,
} from "../AxiosApis/api";

const { TextArea } = Input;

const Engineer = () => {
  const [orders, setOrders] = useState([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingResolve, setLoadingResolve] = useState(false);
  const { userDetails, loading: userLoading } = useContext(UserContext);
  const [rejectionReason, setRejectionReason] = useState("");
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      if (!userDetails || userDetails.role !== "engineer") return;

      setLoadingOrders(true);
      try {
        const response = await getEngineerOrders(userDetails._id);
        setOrders(response.data.data.orders);
      } catch (error) {
        console.error("Error fetching engineer's orders:", error);
      } finally {
        setLoadingOrders(false);
      }
    };

    if (!userLoading && userDetails) {
      fetchOrders();
    }
  }, [userDetails, userLoading]);

  const handleEditClick = (order) => {
    setSelectedOrder(order);
    setIsModalVisible(true);
    setRejectionReason(""); // Reset rejection reason
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setSelectedOrder(null);
    setRejectionReason(""); // Reset rejection reason
  };

  const handleRejectAssignment = async () => {
    if (!rejectionReason) {
      notification.warning({
        message: "Warning",
        description: "Please provide a reason for rejecting the assignment.",
      });
      return;
    }

    setLoadingResolve(true);
    try {
      await cancelAssignEngineer({
        orderId: selectedOrder.orderId,
        engineerId: userDetails._id,
        reason: rejectionReason,
      });
      const updatedOrders = orders.map((order) =>
        order.orderId === selectedOrder.orderId
          ? { ...order, status: "order_At_Store", deliveryPerson: null }
          : order
      );
      notification.success({
        message: "Success",
        description: "Engineer assignment canceled successfully",
      });
      setOrders(updatedOrders);
      setIsModalVisible(false);
    } catch (error) {
      console.error("Error canceling Engineer assignment:", error);
      notification.error({
        message: "Error",
        description: "There was an error canceling the engineer assignment",
      });
    } finally {
      setLoadingResolve(false);
    }
  };

  const handleFileChange = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleResolve = async () => {
    setLoadingResolve(true);
    const formData = new FormData();
    formData.append("orderId", selectedOrder.orderId);
    formData.append("engineerId", userDetails._id);
    fileList.forEach((file) => {
      formData.append("resolvedphotos", file.originFileObj);
    });

    try {
      const response = await markAsResolved(formData);

      if (response.status === 200) {
        const updatedOrders = orders.map((order) =>
          order.orderId === selectedOrder.orderId
            ? { ...order, status: "resolved" }
            : order
        );
        notification.success({
          message: "Success",
          description: "Order Resolved Successfully",
        });
        setOrders(updatedOrders);
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error resolving the order:", error);
    } finally {
      setLoadingResolve(false);
    }
  };

  const handleIssueReported = async () => {
    setLoadingResolve(true);
    try {
      const response = await reportIssue({
        orderId: selectedOrder.orderId,
        engineerId: userDetails._id,
      });

      if (response.status === 201) {
        const updatedOrders = orders.map((order) =>
          order.orderId === selectedOrder.orderId
            ? { ...order, status: "issue_reported" }
            : order
        );
        notification.success({
          message: "Issue",
          description: "Successfully Sent Back",
        });
        setOrders(updatedOrders);
        setIsModalVisible(false);
      }
    } catch (error) {
      console.error("Error reporting the issue:", error);
    } finally {
      setLoadingResolve(false);
    }
  };

  const columns = [
    {
      title: "Order ID",
      dataIndex: "orderId",
      key: "orderId",
    },
    {
      title: "Product Name",
      dataIndex: "productName",
      key: "productName",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status) => (
        <Tag color={status === "resolved" ? "green" : "blue"}>{status}</Tag>
      ),
    },
    {
      title: "Assigned Time",
      dataIndex: "assignEngineerTime",
      key: "assignEngineerTime",
      render: (time) => (time ? new Date(time).toLocaleString() : "N/A"),
    },
    {
      title: "Actions",
      key: "action",
      render: (text, record) => (
        <Space>
          <EditOutlined onClick={() => handleEditClick(record)} />
        </Space>
      ),
    },
  ];

  return (
    <AppLayout>
      <Row gutter={(8, 16)}>
        <Col span={24}>
          <Table
            columns={columns}
            dataSource={orders}
            loading={loadingOrders || userLoading}
            rowKey="_id"
            scroll={{ x: "max-content" }}
          />
        </Col>
      </Row>

      <Modal
        title={`Order ID: ${selectedOrder?.orderId}`}
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button
            type="primary"
            onClick={handleRejectAssignment}
            disabled={selectedOrder?.status === "resolved"}
          >
            Reject Assignment
          </Button>,
          <Button
            key="resolve"
            type="primary"
            loading={loadingResolve}
            onClick={handleResolve}
            disabled={selectedOrder?.status === "resolved"}
          >
            Resolve
          </Button>,
          <Button
            key="issue"
            type="primary"
            loading={loadingResolve}
            onClick={handleIssueReported}
            disabled={selectedOrder?.status === "resolved"}
          >
            Issue Reported
          </Button>,
        ]}
      >
        <p>Product Name: {selectedOrder?.productName}</p>
        <p>Status: {selectedOrder?.status}</p>
        <p>
          Assigned Time:{" "}
          {selectedOrder?.assignEngineerTime
            ? new Date(selectedOrder.assignEngineerTime).toLocaleString()
            : "N/A"}
        </p>
        <TextArea
          rows={4}
          placeholder="Provide a reason for rejecting the assignment"
          value={rejectionReason}
          onChange={(e) => setRejectionReason(e.target.value)}
        />
        <Upload
          listType="picture"
          multiple={true}
          fileList={fileList}
          onChange={handleFileChange}
          beforeUpload={() => false}
          disabled={selectedOrder?.status === "resolved"}
        >
          <Button icon={<UploadOutlined />}>Upload Photos</Button>
        </Upload>
      </Modal>
    </AppLayout>
  );
};

export default Engineer;
